export const FirebaseConfig = {
	"projectId": "drona-7d995",
	"appId": "1:502517740787:web:3a07e08192fe33e2137386",
	"databaseURL": "https://drona-7d995-default-rtdb.firebaseio.com",
	"storageBucket": "drona-7d995.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyAmPQ2_fc2aAnGwDDfv2UWcD0o0mHEqbGM",
	"authDomain": "drona-7d995.firebaseapp.com",
	"messagingSenderId": "502517740787",
	"measurementId": "G-9DVWJ3XK8E"
};